.btech-logo {
  z-index: 30!important;
}

.btech-logo img{
    width: auto;
    height:80px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}