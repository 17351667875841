.Account{
    position: absolute;
    min-height: 80vh;
    min-width: 90vw;
    background-color: whitesmoke;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    border: 1px solid gainsboro;
}
.account-body-btn{
    min-width: 50%
}
.account-body{
    min-height: calc(80vh - 100px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-title{
    font-size: 17px;
    font-weight: 900;
    font-style: italic;
    color: var(--main-color);
}

[dir="rtl"] .account-title{
    font-family: "Montserrat"!important;
}