.Checkout {
  position: absolute;
  min-height: 80vh;
  min-width: 90vw;
  background-color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border: 1px solid gainsboro;
}
.checkout-title {
  font-weight: 900;
  font-style: italic;
  color: var(--secondary-color);
  text-align: center;
}

[dir="rtl"] .checkout-title{
  font-size: 40px;
  font-weight: 200;
}
.checkout-img img {
  max-width: 100%;
}

.label-text-receipt {
  font-weight: 700!important;
  font-size: 13px!important;
  margin-bottom: 0px !important;
  color: whitesmoke!important;
  background-color: #DC3545!important;
}
.input-text-receipt {
  border: 1px solid gainsboro;
  background-color: whitesmoke !important;
  text-align: center;
  color: var(--main-color) !important;
  font-weight: 600 !important;
}

[dir="rtl"] .label-text-receipt{
  font-weight: 200!important;
  font-size: 17px!important;
}
.input-text-receipt:focus {
  box-shadow: none !important;
  border-color: var(--secondary-color) !important;
}
.couponcode,
.total {
  color: var(--light-secondary-color) !important;
  font-weight: 700 !important;
  border: 1px solid var(--light-secondary-color) !important;
  text-align: center;
  background-color: #DC3545 !important;
}
.bbt {
  border-bottom: 1px solid var(--dark-main-color);
}
.checkout-btn {
  min-width: 200px;
  font-weight: 800 !important;
  font-size: 15px;
  background: linear-gradient(
    90deg,
    rgba(227, 161, 48, 1) 0%,
    rgba(191, 26, 22, 1) 100%
  );
  border: none !important;
  padding: 10px;
}

[dir="rtl"] .checkout-btn{
  font-weight: 100!important;
  font-size: 20px;
}
@media (max-width: 575px) {
  .customer-title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .checkout-img {
    display: none;
  }
}
@media (min-width: 992px) {
  .Checkout {
    min-height: 80vh;
  }
}
@media (max-width: 380px) {
  .label-text-receipt {
    font-size: 12px !important;
  }
}

.receipt{
  display: none;
}
.receipt-child{
  min-height: 100vh;
  background-color: white;
}

.receipt-child h4,
.receipt-child h5{
  font-weight: 600;
}
.title{
  font-size: 15px!important;
  text-align: center;
}
.value{
  font-size: 14px!important;
  text-align: center;
}
.ft-style{
  font-size: 15px!important;
  font-weight: 700;
}
.border{
  border-top: 2px dashed black;
}

.back-btn{
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 40px!important;
  background-color: #F5F5F5!important;
}
.back-icon{
  color: var(--dark-main-color);
  font-size: 20px!important;
}