.addUser{
    position: absolute;
    overflow-y: scroll;
    min-height: 80vh;
    min-width: 90vw;
    background-color: whitesmoke;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    border: 1px solid gainsboro;
}
.addUser-title{
    font-weight: 900;
    font-style: italic;
    color: var(--secondary-color);
    text-align: center;
}

[dir="rtl"] .addUser-title{
    font-size: 40px;
    font-weight: 200;
}

.addUser-title-message{
    font-weight: 900;
    /* font-style: italic; */
    color: var(--dark-main-color);
    text-align: center;
}
.submit-btn{
    min-width: 200px;
    font-weight: 800!important;
    font-size: 17px;
    background: linear-gradient(90deg, rgba(227,161,48,1) 0%, rgba(191,26,22,1) 100%);
    border: none!important;
    padding: 10px;
}
.submit-btn:hover{
    opacity: 0.9;

}
.input-text{
    font-size: 13px!important;
}
.scan-btn{
    width: 100%;
}
@media(max-width:767px){
    .label-text{
        font-size: 15px!important;
    }
}
@media(max-width:575px){
    .scan-btn{
        margin-top: 5px;
    }
    .addUser-title{
        font-size: 18px;
    }
    .submit-btn{
        min-width: 150px;
    }
}

/* Toast */
.toast-header{
    font-weight: 600!important;
    font-size: 14px!important;
    background-color: #DC3545!important;
    color:whitesmoke!important;
}
.toast-body{
    font-weight: 700!important;
    font-style: italic;
    color:var(--light-secondary-color)!important;
    background-color: #C6303E!important;
}