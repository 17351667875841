.vouchForm {
  position: absolute;
  min-height: 80vh;
  min-width: 90vw;
  background-color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border: 1px solid gainsboro;
}

.remove-btn {
  max-height: 31px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.send-btn {
  max-height: 29px !important;
}
