.logout {
}

.logout-btn {
  color: whitesmoke !important;
}
.home-btn {
  color: whitesmoke !important;
  font-weight: 600!important;
  font-size: 10px!important;
}
