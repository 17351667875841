.Login { 
    height: 80vh;
    display: flex;
    align-items: center;
}
.character{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 1px solid rgb(131, 11, 10,0.4); */
    /* margin-right: 45px; */
}
.character img{
    max-height: 350px;
}
.shareecoin-logo img{
    max-height: 150px;
}
.label-text{
    font-weight: 900!important;
    font-style: italic;
    font-size: 19px;
    margin-bottom: 0px!important;
    color: var(--main-color);
}
.login-btn{
    min-width: 200px;
    font-weight: 800!important;
    font-size: 17px;
    background: linear-gradient(90deg, rgba(227,161,48,1) 0%, rgba(191,26,22,1) 100%);
    border: none!important;
    padding: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    color: whitesmoke!important;

}
.login-btn:hover{
    opacity: 0.9;

}
.login-form{
    position: relative;
}
.input-text{
    border: 1px solid var(--main-color)!important;
    background-color: whitesmoke!important;

}
.input-text:focus{
    box-shadow: none!important;
}
.text-error {
    color :  #dc3545!important;
    font-weight: 500!important;
    font-size: .750em!important;
}
@media(max-width:767px){
    .character{
        display: none;
    }
}
.phone-addon{
    border: 1px solid var(--main-color)!important;
    background-color: white!important;
}


/* Toast */
.toast-header{
    font-weight: 600!important;
    font-size: 14px!important;
    background-color: #DC3545!important;
    color:whitesmoke!important;
}
.toast-body{
    font-weight: 700!important;
    font-style: italic;
    color:var(--light-secondary-color)!important;
    background-color: #C6303E!important;
}
/* Toast */