.account-btn{
    background: linear-gradient(90deg, rgba(227,161,48,1) 0%, rgba(191,26,22,1) 100%);
    font-weight: 600!important;
    font-size: 17px!important;
    font-style: italic;
    color: whitesmoke!important;
    border: 0px!important;
}
[dir="rtl"] .account-btn{
    font-weight: 200!important;
    font-size: 23px!important;
}

[dir="rtl"] .btn-close{
    margin: 0!important;
}
.account-btn:hover{
    opacity: 0.95;
}
.modal-header{
    background: var(--main-color);
    font-style: italic!important;
    color: var(--light-secondary-color)!important;
}
.add-user-btn{
    text-decoration: none;
    color: black!important;
    font-weight: 500!important;
}
.add-user-btn:hover{
    color: var(--main-color);
}
thead tr th {
    font-size: 15px;
}
thead tr th input {
    max-height: 30px!important;
    font-size: 13px!important;
}

tbody tr td {
    font-size: 13px;
    font-weight: 600;
}
.react-bootstrap-table{
    width: 100%;
    overflow-x: auto;
}
.react-bootstrap-table table {
    table-layout: unset;
}

