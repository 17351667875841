.changeps {
  position: absolute;
  min-height: 80vh;
  min-width: 90vw;
  background-color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border: 1px solid gainsboro;
}
.changeps-title{
    font-weight: 900;
    font-style: italic;
    color: var(--secondary-color);
    text-align: center;
}

[dir="rtl"] .changeps-title{
  font-size: 40px;
  font-weight: 200;
}


/* Toast */
.toast-header-success{
  font-weight: 600!important;
  font-size: 14px!important;
  background-color: #24963E!important;
  color:whitesmoke!important;
}
.toast-body-success{
  font-weight: 700!important;
  font-style: italic;
  color: white!important;
  background-color: #28A745!important;
}
/* Toast */