body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  box-sizing: border-box;
}

:root{
  --main-color: #bf1a16;
  --secondary-color: #e3a130;
  --dark-main-color: #830b0a;
  --light-secondary-color: #fdd75d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "jenine-bold";
  src: url("./fonts/ArbFONTS-Jenine-Bold.otf");
}

@font-face {
  font-family: "jenine-medium";
  src: url("./fonts/ArbFONTS-Jenine-Medium.otf");
}

@font-face {
  font-family: "jenine-semibold";
  src: url("./fonts/ArbFONTS-Jenine-SemiBold.otf");
}

@font-face {
  font-family: "jenine-ultralight";
  src: url("./fonts/ArbFONTS-Jenine-UltraLight.otf");
}

*{
  font-family: "Montserrat";
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance: none;
}

[ dir = rtl ] *{
  font-family: "jenine-medium";
}

.error_message{
  font-size: 14px!important;
}

.btn:focus{
  box-shadow: none!important;
}
