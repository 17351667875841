.Language{
    z-index: 30!important;
    position: absolute;
    bottom: 0%;
}
.menu-style{
    background-color: var(--main-color)!important;
    font-size: 12px!important;
}
.dropdown-item{
    color: var(--light-secondary-color)!important;
    font-weight: 500!important;
}
.dropdown-item:hover{
    background-color: var(--dark-main-color)!important;
}
.icon-style{
    color: var(--light-secondary-color)!important;
}
.dropdown-toggle::after{
    color: var(--light-secondary-color)!important;
    margin-right: 0.255em;
}
